import React, { FC } from 'react'
import * as S from './styled'

type PillVariant = 'accent'

type Props = {
  label: string
  backgroundColor?: string
  textColor?: string
  variant?: PillVariant
  onClick?: () => void
}

const PillCore: FC<Props> = ({ label, backgroundColor, textColor, onClick }) => {
  const isClickable = !!onClick

  return (
    <S.Container backgroundColor={backgroundColor} isClickable={isClickable} onClick={onClick}>
      <S.Text textColor={textColor}>{label}</S.Text>
    </S.Container>
  )
}

const New = (props: Omit<Props, 'backgroundColor' | 'textColor'>) => (
  <PillCore {...props} backgroundColor="var(--accent)" textColor="var(--background)" />
)

const Pill = Object.assign(PillCore, { New })

export default Pill
