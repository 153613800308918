import React from 'react'

function Twitter({ fill = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill={fill}
        d="M20 5.634a6.965 6.965 0 01-2.003.549 3.502 3.502 0 001.533-1.928 7.03 7.03 0 01-2.216.847 3.488 3.488 0 00-5.942 3.18 9.9 9.9 0 01-7.188-3.645 3.461 3.461 0 00-.471 1.753c0 1.21.616 2.278 1.55 2.902a3.488 3.488 0 01-1.579-.437v.043a3.49 3.49 0 002.797 3.42c-.292.078-.6.122-.919.122-.225 0-.443-.022-.656-.065a3.49 3.49 0 003.257 2.422 6.996 6.996 0 01-4.331 1.49c-.282 0-.559-.016-.832-.047a9.857 9.857 0 005.345 1.569c6.416 0 9.922-5.314 9.922-9.922l-.011-.451A6.964 6.964 0 0020 5.633z"
      ></path>
      <mask id="mask0" width="17" height="14" x="3" y="4" maskUnits="userSpaceOnUse">
        <path
          fill={fill}
          d="M20 5.634a6.965 6.965 0 01-2.003.549 3.502 3.502 0 001.533-1.928 7.03 7.03 0 01-2.216.847 3.488 3.488 0 00-5.942 3.18 9.9 9.9 0 01-7.188-3.645 3.461 3.461 0 00-.471 1.753c0 1.21.616 2.278 1.55 2.902a3.488 3.488 0 01-1.579-.437v.043a3.49 3.49 0 002.797 3.42c-.292.078-.6.122-.919.122-.225 0-.443-.022-.656-.065a3.49 3.49 0 003.257 2.422 6.996 6.996 0 01-4.331 1.49c-.282 0-.559-.016-.832-.047a9.857 9.857 0 005.345 1.569c6.416 0 9.922-5.314 9.922-9.922l-.011-.451A6.964 6.964 0 0020 5.633z"
        ></path>
      </mask>
      <g mask="url(#mask0)">
        <path fill={fill} d="M0 0H22V22H0z"></path>
      </g>
    </svg>
  )
}

export default Twitter
