import styled from 'styled-components'
import { breakpoints } from '../../../theme/mediaBreakpoints'
import { Body3, bodyFontBold } from '../../../theme/typography'

export const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: min(340px, 100%);
  max-width: 100%;
  padding: 16px 28px 16px 16px;
  border-radius: 8px;
  background: var(--foreground-variant);
  cursor: pointer;

  &:hover {
    background: var(--background-variant-light);
  }

  ${breakpoints.mobile} {
    width: 100%;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Name = styled.p`
  ${bodyFontBold}
  font-size: 1.8rem;
  color: white;
`

export const Title = styled(Body3)`
  font-size: 1.6rem;
`
