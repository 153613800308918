import styled, { css } from 'styled-components'

export const Container = styled.div``

const activePictureSize = 48
const inactivePictureSize = 40

export const PicturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 6px;
`

export const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${activePictureSize}px;
  width: ${activePictureSize}px;
  cursor: pointer;
`

type PictureProps = {
  active: boolean
}

export const Picture = styled.img<PictureProps>`
  height: ${activePictureSize}px;
  width: ${activePictureSize}px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  object-position: top center;

  ${({ active }) =>
    !active &&
    css`
      height: ${inactivePictureSize}px;
      width: ${inactivePictureSize}px;
      opacity: 0.4;
      filter: grayscale(1);
    `};
`
