import styled from 'styled-components'
import { hrefHover } from '../../theme/base'
import { breakpoints } from '../../theme/mediaBreakpoints'
import { Body4, bodyFontBold } from '../../theme/typography'

export const Container = styled.footer`
  background: var(--black);
  padding: 7.2rem 0 3.2rem 0rem;

  ${breakpoints.mobile} {
    padding: 3rem;
  }
`

export const MenuTitle = styled.h3`
  color: white;
  ${bodyFontBold}
  font-size: 2.4rem;
  letter-spacing: 2px;
`

export const Social = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 2.4rem;
    ${hrefHover}
  }
`

export const Link = styled(Body4).attrs(() => ({ white: true }))`
  ${hrefHover}
`

export const BottomArea = styled.div`
  display: flex;
  justify-content: space-between;
`
