import React from 'react'

function Instagram({ fill = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.033 2H6.966A4.973 4.973 0 002 6.967v8.066A4.973 4.973 0 006.967 20h8.066A4.973 4.973 0 0020 15.033V6.967A4.973 4.973 0 0015.033 2zm3.37 13.033a3.374 3.374 0 01-3.37 3.37H6.967a3.374 3.374 0 01-3.37-3.37V6.967a3.374 3.374 0 013.37-3.37h8.066a3.374 3.374 0 013.37 3.37v8.066zM6.286 11A4.72 4.72 0 0111 6.286 4.72 4.72 0 0115.714 11 4.72 4.72 0 0111 15.714 4.72 4.72 0 016.286 11zM11 14.09A3.094 3.094 0 017.909 11 3.095 3.095 0 0111 7.909 3.095 3.095 0 0114.091 11 3.094 3.094 0 0111 14.09z"
        clipRule="evenodd"
      ></path>
      <mask id="mask0" width="18" height="18" x="2" y="2" maskUnits="userSpaceOnUse">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M15.033 2H6.966A4.973 4.973 0 002 6.967v8.066A4.973 4.973 0 006.967 20h8.066A4.973 4.973 0 0020 15.033V6.967A4.973 4.973 0 0015.033 2zm3.37 13.033a3.374 3.374 0 01-3.37 3.37H6.967a3.374 3.374 0 01-3.37-3.37V6.967a3.374 3.374 0 013.37-3.37h8.066a3.374 3.374 0 013.37 3.37v8.066zM6.286 11A4.72 4.72 0 0111 6.286 4.72 4.72 0 0115.714 11 4.72 4.72 0 0111 15.714 4.72 4.72 0 016.286 11zM11 14.09A3.094 3.094 0 017.909 11 3.095 3.095 0 0111 7.909 3.095 3.095 0 0114.091 11 3.094 3.094 0 0111 14.09z"
          clipRule="evenodd"
        ></path>
      </mask>
    </svg>
  )
}

export default Instagram
