import React, { FC } from 'react'
import Facebook from '../../assets/svg/Facebook'
import Instagram from '../../assets/svg/Instagram'
import Linkedin from '../../assets/svg/Linkedin'
import Twitter from '../../assets/svg/Twitter'
import CustomLink from '../__general/CustomLink'
import * as S from './styled'

type Props = {
  mode?: 'dark' | 'light'
  facebook?: string
  linkedin?: string
  instagram?: string
  twitter?: string
}

const SocialLinks: FC<Props> = ({ facebook, linkedin, instagram, twitter, mode = 'light' }) => {
  const fill = mode === 'light' ? '#FFF' : '#000'

  return (
    <S.Container>
      {linkedin && (
        <CustomLink aria-label="Linkedin" to={linkedin} target="_blank" rel="noopener noreferrer">
          <Linkedin fill={fill} />
        </CustomLink>
      )}
      {twitter && (
        <CustomLink aria-label="Twitter" to={twitter} target="_blank" rel="noopener noreferrer">
          <Twitter fill={fill} />
        </CustomLink>
      )}
      {facebook && (
        <CustomLink aria-label="Facebook" to={facebook} target="_blank" rel="noopener noreferrer">
          <Facebook fill={fill} />
        </CustomLink>
      )}
      {instagram && (
        <CustomLink aria-label="Instagram" to={instagram} target="_blank" rel="noopener noreferrer">
          <Instagram fill={fill} />
        </CustomLink>
      )}
    </S.Container>
  )
}

export default SocialLinks
