import { keyframes } from '@emotion/react'
import { css, keyframes as K } from 'styled-components'

/**
 * @emotion/react animations are for react-awesome-reveal
 * styled-components animations are for mixins
 * TODO: Refactor
 */

const slideUp3D = K`
  from {
    transform: scaleY(0.01) translate3d(0, 550px, 0) skew(-10deg, -30deg);
    opacity: 0;
    transform-origin: bottom left;
  }
  to {
    transform: scaleX(1) translateZ(0) skew(0deg, 0deg);
    opacity: 1;
    transform-origin: bottom left;
  }
  `

const zoomFade = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const slideUp = keyframes`
  from {
    transform: translateY(25%);
  }
  to {
    transform: translateY(0);
  }
`

const slideUpFadeIn = keyframes`
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const animations = {
  zoomFade,
  slideUp,
  slideUpFadeIn,
  slideUp3D,
}

const animSlideUp3D = css`
  animation: ${animations.slideUp3D} 0.8s cubic-bezier(0.445, 0.74, 0.095, 1.185) forwards;
`

export const completeAnimations = {
  animSlideUp3D,
}

export default animations
