import React from 'react'

function Facebook({ fill = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill={fill}
        d="M12.499 19v-7.298h2.496l.375-2.845h-2.871V7.041c0-.823.232-1.384 1.437-1.384l1.535-.001V3.111A21.214 21.214 0 0013.234 3c-2.214 0-3.73 1.326-3.73 3.76v2.097H7v2.845h2.504V19h2.995z"
      ></path>
      <mask id="mask0" width="9" height="16" x="7" y="3" maskUnits="userSpaceOnUse">
        <path
          fill={fill}
          d="M12.499 19v-7.298h2.496l.375-2.845h-2.871V7.041c0-.823.232-1.384 1.437-1.384l1.535-.001V3.111A21.214 21.214 0 0013.234 3c-2.214 0-3.73 1.326-3.73 3.76v2.097H7v2.845h2.504V19h2.995z"
        ></path>
      </mask>
    </svg>
  )
}

export default Facebook
