import styled, { css } from 'styled-components'
import { breakpoints } from '../../../theme/mediaBreakpoints'
import { bodyFontRegular } from '../../../theme/typography'

type ContainerProps = {
  backgroundColor?: string
  isClickable: boolean
}

const clickableContainerCss = css`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const Container = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor = 'var(--background-variant)' }) => backgroundColor};
  padding: 12px 16px;
  min-width: 64px;
  border-radius: 4px;
  padding: 12px 20px;
  border-radius: 100px;
  min-width: 80px;

  ${breakpoints.mobile} {
    padding: 10px 14px;
  }

  ${({ isClickable }) => isClickable && clickableContainerCss}
`

type TextProps = {
  textColor?: string
}

export const Text = styled.p<TextProps>`
  ${bodyFontRegular}
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0.3px;
  color: ${({ textColor = 'var(--foreground)' }) => textColor};
  text-align: center;

  ${breakpoints.mobile} {
    font-size: 15px;
  }
`
