import styled from 'styled-components'
import { hrefHover } from '../../theme/base'

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 2.4rem;
    ${hrefHover}
  }

  & > *:first-child {
    margin-left: 0;
  }
`
