import React, { FC, MouseEvent, useEffect, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { Spacer } from '../../theme/base'
import { Title4 } from '../../theme/typography'
import { Employee } from '../../types'
import { getImageUrl } from '../../util/helpers'
import CustomLink from '../__general/CustomLink/CustomLink'
import SocialCard from '../__general/SocialCard'
import * as S from './styled'

type Props = {
  employees: Employee[]
}

const TeamSlideShow: FC<Props> = ({ employees }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    const goToNext = () => {
      if (isPaused) return
      setCurrentIndex(prev => (prev === employees.length - 1 ? 0 : prev + 1))
    }

    const slideShowInterval = setInterval(goToNext, 2200)

    return () => clearInterval(slideShowInterval)
  }, [isPaused, employees.length])

  const handleMouseEnterImage = (e: MouseEvent<HTMLImageElement>) => {
    setIsPaused(true)
    const viewedIndex = +(e.currentTarget.getAttribute('data-id') as string)
    setCurrentIndex(viewedIndex)
  }

  const handleMouseLeaveImage = () => {
    setIsPaused(false)
  }

  const handleClickImage = (e: MouseEvent<HTMLImageElement>) => {
    handleMouseEnterImage(e)
    setIsPaused(true)
  }

  const pictures = employees.map(employee => getImageUrl(employee.picture.fixed))
  const { name, title, linkedin, fauxTitle } = employees[currentIndex]

  const pictureList = pictures.map((picture, i) => (
    <CustomLink
      aria-label="Linkedin"
      to={linkedin}
      disabled={isMobile}
      target="_blank"
      rel="noopener noreferrer"
      key={i}
    >
      <S.PictureWrapper
        data-id={`${i}`}
        onClick={handleClickImage}
        {...(!isMobile && {
          onMouseEnter: handleMouseEnterImage,
          onMouseLeave: handleMouseLeaveImage,
        })}
      >
        <S.Picture active={i === currentIndex} src={picture} />
      </S.PictureWrapper>
    </CustomLink>
  ))

  return (
    <S.Container>
      <Title4 white>Meet the team.</Title4>
      <Spacer h28 />
      <S.PicturesWrapper>{pictureList}</S.PicturesWrapper>
      <Spacer h28 />
      <div onMouseEnter={() => setIsPaused(true)} onMouseLeave={handleMouseLeaveImage}>
        <SocialCard name={name} title={title} linkedin={linkedin} fauxTitle={fauxTitle} />
      </div>
    </S.Container>
  )
}

export default TeamSlideShow
