import React, { FC } from 'react'
import Linkedin from '../../../assets/svg/Linkedin'
import { Spacer } from '../../../theme/base'
import CustomLink from '../CustomLink/CustomLink'
import * as S from './styled'

type Props = {
  name: string
  title: string
  fauxTitle?: string
  linkedin: string
}

const SocialCard: FC<Props> = ({ name, title, fauxTitle, linkedin }) => {
  return (
    <CustomLink aria-label="Linkedin" to={linkedin} target="_blank" rel="noopener noreferrer">
      <S.Container>
        <S.TextWrapper>
          <S.Name>{name}</S.Name>
          <S.Title light color="white">
            {title}
          </S.Title>

          <S.Title style={{ fontStyle: 'italic' }} color="white">
            {fauxTitle ? `“${fauxTitle}”` : '‎ '}
          </S.Title>

          <S.Title light color="white"></S.Title>
        </S.TextWrapper>
        <div style={{ display: 'flex' }}>
          <Spacer w16 />
          <Linkedin fill="white" />
        </div>
      </S.Container>
    </CustomLink>
  )
}

export default SocialCard
