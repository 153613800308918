import { graphql, useStaticQuery } from 'gatsby'
import React, { Fragment } from 'react'
import { Col, Container, Row, Visible } from 'react-grid-system'
import constants from '../../config/constants'
import { Spacer } from '../../theme/base'
import { Body2, Body3 } from '../../theme/typography'
import { Employee } from '../../types'
import EmployeeSlideShow from '../EmployeeSlideShow'
import { routes } from '../Header/Header'
import SocialLinks from '../SocialLinks/SocialLinks'
import CustomLink from '../__general/CustomLink/CustomLink'
import Divider from '../__general/Divider'
import * as S from './styled'

const query = graphql`
  query {
    allContentfulEmployee {
      edges {
        node {
          linkedin
          name
          title
          fauxTitle
          picture {
            fixed(height: 200) {
              srcWebp
              src
            }
          }
        }
      }
    }
  }
`

const Footer = () => {
  const { allContentfulEmployee } = useStaticQuery(query)

  const employeeList: Employee[] = allContentfulEmployee.edges.map(edge => edge.node)

  return (
    <S.Container>
      <Container>
        <Row style={{ rowGap: '20px' }}>
          <Col lg={4} md={12} sm={12}>
            <EmployeeSlideShow employees={employeeList} />
            <Visible sm xs>
              <Spacer h40 />
            </Visible>
          </Col>
          <Col lg={2} />
          <Col lg={2} md={4} sm={12}>
            <S.MenuTitle>Menu</S.MenuTitle>
            {routes.map(route => (
              <Fragment key={route.route}>
                <Spacer h24 />
                <CustomLink to={route.route}>
                  <Body3 white light lightHover>
                    {route.title}
                  </Body3>
                </CustomLink>
              </Fragment>
            ))}
            <Visible xs>
              <Spacer h40 />
            </Visible>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <S.MenuTitle>Connect</S.MenuTitle>
            <Spacer h24 />
            <a
              aria-label="Linkedin"
              href={constants.socialLinks.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Body3 white light lightHover>
                LinkedIn
              </Body3>
            </a>
            <Spacer h24 />
            <a
              aria-label="Instagram"
              href={constants.socialLinks.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Body3 white light lightHover>
                Instagram
              </Body3>
            </a>
            <Visible xs>
              <Spacer h40 />
            </Visible>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <S.MenuTitle>Contact</S.MenuTitle>
            <Spacer h24 />
            <a href={`mailto:${constants.contactEmail}`} target="_blank" rel="noopener noreferrer">
              <Body3 white light lightHover>
                Hello@redmind.se
              </Body3>
            </a>
            <Spacer h24 />
            <Body3 white>{constants.addresses.stockholm}</Body3>
            <Spacer h24 />
            <Body3 white>{constants.addresses.linkoping}</Body3>
          </Col>
        </Row>
        <Spacer size="large" />
        <Divider />
        <Spacer h32 />
        <S.BottomArea>
          <Body2 uppercase white>
            Redmind © {new Date().getFullYear()}
          </Body2>
          <SocialLinks
            linkedin={constants.socialLinks.linkedin}
            facebook={constants.socialLinks.facebook}
            instagram={constants.socialLinks.instagram}
          />
        </S.BottomArea>
        <Spacer h32 />
      </Container>
    </S.Container>
  )
}

export default Footer
