const constants = {
  contactEmail: 'hello@redmind.se',
  addresses: {
    stockholm: 'Skomakargatan 34, 111 29 Stockholm',
    linkoping: 'Fridtunagatan 24, 582 12 Linköping',
  },
  socialLinks: {
    linkedin: 'https://www.linkedin.com/company/1926872',
    facebook: 'https://www.facebook.com/Weareredmind/',
    instagram: 'https://www.instagram.com/weareredmind',
  },
}

export default constants
